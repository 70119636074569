import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { send, notify } from "../../../services/NotificationService";
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { Panel } from 'primereact/panel';
import { IsNullOrEmpty } from "../../../services/CustomService";
import { useFormik } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import 'moment/locale/fr';
import {
    useLoaderData,
    Form,
    useFetcher,
    useSubmit
} from "react-router-dom";
import { getSource, updateFavorite, getBalance, sendOrder } from "../../../data/money/sources";
import { getLast, deleteTransaction, getOrder, cancelOrder } from "../../../data/money/futures";
export async function action({ request, params }) {
    let formData = await request.formData();
    return updateFavorite(params.sourceId, {
        favorite: formData.get("favorite") === "true",
    });
}
export async function loader({ params }) {
    const source = await getSource(params.sourceId);
    const balance = await getBalance(params.sourceId);
    const lastTransactions = await getLast();
    if (!source) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
 
    return { source, balance, lastTransactions };
}
export default function Source() {
    const { source, balance, lastTransactions } = useLoaderData();
    const [expandedRows, setExpandedRows] = useState(null);


    const items = [
        {
            label: source.name,
            template: () => <span>{source.name}</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    const submit = useSubmit();
    const accept = () => {
        submit(null, { method: "post", action: "delete" })
    };

    const reject = () => {
    };

    const deleteSource = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir supprimer cette source ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept,
            reject
        });
    };

    
   




    // Formik ---------------------

    const formik = useFormik({
        initialValues: {
            percent: 10,
            balance: balance,
            size: 0,
            pair: "BTCPERP",
            pe: "",
            sl: ""
        },
        validate: (data) => {
            let errors = {};

            if (!data.percent) {
                errors.price = 'percent is required.';
            }
            if (!data.balance) {
                errors.balance = 'balance is required.';
            }
            if (!data.sl) {
                errors.sl = 'sl is required.';
            }
            if (!data.pe) {
                errors.pe = 'pe is required.';
            }

            return errors;
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const cancelOrderAction = (event, rowData, market) => {
      
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir annuler l\'ordre ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => { acceptCancelOrder(rowData.id, market); },
            reject
        });
    };
    const acceptCancelOrder = (id, market) => {
        cancelOrder(id, market).then((ret) => {
            if (ret.status === 200) {
                send("success", ret.message)
                notify();
            } else {
                send("error", ret.message)
                notify();
            }
        });
    };

    //----------------------------------------
    const copyValues = (rowData) => {
        // prefill values
        formik.setFieldValue('pair', rowData.pair);
        formik.setFieldValue('balance', rowData.balance);
        formik.setFieldValue('pe', rowData.entryPoint);
        formik.setFieldValue('sl', rowData.stopLoss);
        formik.setFieldValue('tp', rowData.takeProfit);
        formik.setFieldValue('size', rowData.size);
    };

    const deleteTransac = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir supprimer cette transaction ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => { acceptDeleteTransac(id); },
            reject
        });
    };
    const acceptDeleteTransac = (id) => {

        deleteTransaction(id).then((ret) => {
            send("success", "Suppression OK");
            // reload
            window.location.reload();
        });



    };
    const calculRisk = (event) => {
        const balance = formik.values.balance;
        const percent = formik.values.percent;
        const pe = formik.values.pe;
        const sl = formik.values.sl;
        const tp = formik.values.tp;
        const risk = (balance * percent) / 100;
        var reward = 0;
        var ri = 0;
        if (tp > pe) {
            reward = tp - pe;
            ri = pe - sl;


        }
        else {
            reward = pe - tp;
            ri = sl - pe;
        }
        var rr = reward / ri;


        var diff = pe - sl;
        var size = risk / diff;
        formik.setFieldValue('size', Math.abs(size));
        document.querySelector('.rr').innerHTML = rr;
        
    };

    const sendOrderAction = (event) => {
        // on verifie les champs
        var err = false;
        var mess = "";
        if (IsNullOrEmpty(formik.values.pe)) {
            send("error", "Le prix d'entrée est obligatoire");
            err = true;
        }
        if (IsNullOrEmpty(formik.values.sl)) {
            send("error", "Le stop est obligatoire");
            err = true;
        }
        if (IsNullOrEmpty(formik.values.size )) {
            send("error", "La taille est obligatoire");
            err = true;
        }
        if (IsNullOrEmpty(formik.values.pair)) {
            send("error", "La paire est obligatoire");
            err = true;
            
        }
        if (err) {
            notify();
            return;
        }
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir envoyer l\'ordre ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => { acceptOrder(); },
            reject
        });
    };
    const acceptOrder = () => {
        const pe = formik.values.pe.toString();
        const sl = formik.values.sl.toString();
        const tp = formik.values.tp.toString();
        const size = formik.values.size;
        const pair = formik.values.pair;
        var r = sendOrder(pe, sl, tp, size, pair);
        r.then((ret) => {
            if (ret.status === 200) {
                send("success", ret.message)
                notify();
            } else {
                send("error", ret.message)
                notify();
            }

        });
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">

               
                <div className="contentForm">
                    <span className="p-float-label">
                        <b >30% :</b>   <span>{data.size * 30 / 100}</span> <b >50% :</b> <span >{data.size * 50 / 100}</span>
                    </span>
                </div >
                <div className="contentForm">

                    <span className="p-float-label">
                        <Button onClick={(e) => cancelOrderAction(e,data, false)} icon="pi pi-times" label="" size="small" className="p-button-danger"></Button>&nbsp;
                        <Button onClick={(e) => cancelOrderAction(e,data, true)} icon="pi pi-times" label="" size="small" className="p-button-danger">&nbsp; Annuler + Market</Button>
                    </span>


                    
                </div >
            </div>
        );
    };

    const actionsBodyTemplate = (rowData) => {
        return (
              <div>
                <Button onClick={() => copyValues(rowData)} icon="pi pi-copy" label="" size="small" ></Button>&nbsp;
                <Button onClick={(e) => deleteTransac(e, rowData.id)} icon="pi pi-trash" label="" size="small" className="p-button-danger"></Button>
            </div>
            

        );
    };
    const onRowExpand = (event) => {
        // get order
        getOrder(event.data.id).then((ret) => {
            console.log(ret);
        });
    };

    const onRowCollapse = (event) => {
        //send("info", "Product Collapsed");
        //notify();
    };
    const allowExpansion = (rowData) => {
        return true;
    };
    const rowClass = (data) => {
        if (data.entryPoint > data.stopLoss)
            return 'position-long';
        else return 'position-short';
    };
    return (
        <div>
            <BreadCrumb model={items} home={home} />
        <div id="thumbnail">
            <div>
                <img height="192" key={source.publicId} alt={source.name} src={source.base64Picture || null} />
            </div>

            <div>
                <h1>
                    {source.name ? (
                        <>
                            {source.name}
                        </>
                    ) : (
                        <i>Inconnu</i>
                    )}{" "}
                    <Favorite source={source} />
                </h1>

                {source.link && (
                    <p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={source.link}
                        >
                            {source.link}
                        </a>
                    </p>
                )}


                <div>
                        <Form action="edit">
                            <Button type="submit" size="small">Modifier</Button>
                    </Form>
                    <Form action="assets/create">
                            <Button type="submit" size="small">Créer un actif</Button>
                    </Form>
                        <ConfirmPopup />
                        <Button onClick={deleteSource} icon="pi pi-trash" label="" size="small" className="p-button-danger"></Button>
   

                       
                </div>

            </div>

            </div>


            <div id="mainContent">
                <Panel header="Capital / Risque">
                    <div className="contentForm">
                    <span className="p-float-label">
                            <InputText id="pair" name="pair" value={formik.values.pair}
                                onChange={(e) => {
                                    formik.setFieldValue('pair', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('pair') })}

                            />
                            <label htmlFor="pair">Pair</label>
                            </span>
                        <span className="p-float-label">
                            <InputText id="balance" name="balance" value={formik.values.balance}
                                keyfilter="num"
                                onChange={(e) => {
                                    formik.setFieldValue('balance', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('balance') })}
                            />
                            <label htmlFor="balance">Balance</label>
                        </span>
                        <span className="p-float-label">
                            <InputText id="percent" name="percent" value={formik.values.percent}
                                keyfilter="num"
                                onChange={(e) => {
                                    formik.setFieldValue('percent', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('percent') })}
                            />
                            <label htmlFor="percent">Risk %</label>
                        </span>
                    </div >
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
                <Panel header="Entrée / Sortie">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="pe" name="pe" value={formik.values.pe}
                                keyfilter="num"
                                onChange={(e) => {
                                    formik.setFieldValue('pe', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('pe') })}
                            />
                            <label htmlFor="pe">Prix d'entrée</label>
                        </span>

                        <span className="p-float-label">
                            <InputText id="sl" name="sl" value={formik.values.sl}
                                keyfilter="num"
                                onChange={(e) => {
                                    formik.setFieldValue('sl', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('sl') })}
                            />
                            <label htmlFor="sl">Stop</label>

                        </span>
                        <span className="p-float-label">
                            <InputText id="tp" name="tp" value={formik.values.tp}
                                keyfilter="num"
                                onChange={(e) => {
                                    formik.setFieldValue('tp', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('tp') })}
                            />
                            <label htmlFor="tp">TP</label>

                        </span>
                        <span className="p-float-label">
                            <Button onClick={calculRisk} icon="pi pi-calculator" label="" size="small" ></Button>&nbsp;
                            

                        </span>
                        <span className="p-float-label">
                            <Button onClick={sendOrderAction} icon="pi pi-send" label="" size="small" ></Button>

                        </span>

                    </div >
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
             
                <Panel header="Position nécessaire">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="size" name="size" value={formik.values.size}
                                keyfilter="num"
                                onChange={(e) => {
                                    formik.setFieldValue('size', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('size') })}
                            />

                        </span>
                       
                    </div >
                    <div className="contentForm"> RR :&nbsp;<span className="rr"></span ></div >
                   
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >

                <h2>Dernières transactions</h2>
                <div className="card">
                    <DataTable rowClassName={rowClass}  selectionMode="single" value={lastTransactions} paginator showGridlines rows={20} dataKey="id" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}  emptyMessage="Aucune transaction">
                        <Column expander={allowExpansion} style={{ width: '5rem' }} />
                        <Column field="pair" header="Actif" />
                        <Column field="balance" header="Solde" />
                        <Column field="entryPoint" header="Point d'entrée" />
                        <Column field="stopLoss" header="Stop Loss" />
                        <Column field="takeProfit" header="TP" />
                        <Column field="size" header="Taille de position" />
                        <Column field="createdAt" header="Date" />
                        <Column header="Actions" body={actionsBodyTemplate} />
                    </DataTable>
                   
           
                </div>
               

                

                
            </div>

    </div>
    );
}

function Favorite({ source }) {
    const fetcher = useFetcher();
    // yes, this is a `let` for later
    let favorite = source.isFavorite;
    if (fetcher.formData) {
        favorite = fetcher.formData.get("favorite") === "true";
    }
    return (
        <fetcher.Form method="post">
            <button
                name="favorite"
                value={favorite ? "false" : "true"}
                aria-label={
                    favorite
                        ? "Remove from favorites"
                        : "Add to favorites"
                }
            >
                {favorite ? "★" : "☆"}
            </button>
        </fetcher.Form>
    );
}