import {  toast } from 'react-toastify';

export function IsNullOrEmpty(str) {
    return (str === "" || str === null || str === undefined || str === "undefined")
}

export function send(type, message) {
    var data = {
        type: type,
        message: message
    }
    //to json
    var json = JSON.stringify(data);
    localStorage.setItem("notification", json)
}

export function notify() {
    let notif = localStorage.getItem("notification");

    if (notif) {
        localStorage.removeItem("notification");
        var message = JSON.parse(notif).message;
        var type = JSON.parse(notif).type;
        if (type === "error") {
            const notify = (mess) => toast.error(mess);
            notify(message);
        } else if (type === "success") {
            const notify = (mess) => toast.success(mess);
            notify(message);
        } else if (type === "warning") {
            const notify = (mess) => toast.warn(mess);
            notify(message);
        }
        else {
            const notify = (mess) => toast.info(mess);
            notify(message);
        }

    }

    return null;
}
