import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { send, notify } from "../../../services/NotificationService";
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';

import { useFormik } from 'formik';
import 'moment/locale/fr';
import {
    useLoaderData,
    Form,
    useFetcher,
    useSubmit
} from "react-router-dom";
import { getSource, updateFavorite, runBtcDcaReverse, runEthDcaReverse, runDcaBuy, getStats, getLastRun } from "../../../data/money/sources";
export async function action({ request, params }) {
    let formData = await request.formData();
    return updateFavorite(params.sourceId, {
        favorite: formData.get("favorite") === "true",
    });
}
export async function loader({ params }) {
    const source = await getSource(params.sourceId);
    if (!source) {
        throw new Response("", {
            status: 404,
            statusText: "Not Found",
        });
    }
    const buyStats = await getStats("DcaBuy");
    const sellStats = await getStats("DcaSell");

    const lasrBuyRun = await getLastRun("DcaBuy");
    const lastSellRun = await getLastRun("DcaSell");
    return { source, buyStats, sellStats, lasrBuyRun, lastSellRun };
}
export default function Source() {
    const { source, buyStats, sellStats, lasrBuyRun, lastSellRun } = useLoaderData();




    const [chartBuyData, setChartBuyData] = useState({});
    const [chartSellData, setChartSellData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        var buyLbls = [];
        var buyValuesAccu = [];
        var v = 0;
        var buyValues = [];
        for (let i = 0; i < buyStats.length; i++) {
            v += buyStats[i].item2;
            buyLbls.push(buyStats[i].item1);
            buyValuesAccu.push(v);
            buyValues.push(buyStats[i].item2);
        }
        
        const buyData = {
            
            labels: buyLbls,
            datasets: [
                {
                    type: 'line',
                    label: 'Progression cumulée',
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: buyValuesAccu
                },
                {
                    type: 'bar',
                    label: '%/trade',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    data: buyValues,
                    borderColor: 'white',
                    borderWidth: 2
                }
            ]
        };

        var sellLbls = [];
        var sellValuesAccu = [];
        var sv = 0;
        var sellValues = [];
        for (let i = 0; i < sellStats.length; i++) {
            sv += sellStats[i].item2;
            sellLbls.push(sellStats[i].item1);
            sellValuesAccu.push(sv);
            sellValues.push(sellStats[i].item2);
        }
        const sellData = {
            labels: sellLbls,
            datasets: [
                {
                    type: 'line',
                    label: 'Progression cumulée',
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: sellValuesAccu
                },
                {
                    type: 'bar',
                    label: '%/trade',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    data: sellValues,
                    borderColor: 'white',
                    borderWidth: 2
                }
            ]
        };



        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
        setChartBuyData(buyData);
        setChartSellData(sellData);
        setChartOptions(options);
    }, []);




    const items = [
        {
            label: source.name,
            template: () => <span>{source.name}</span>
        }
    ];
    const home = { icon: 'pi pi-home', url: '/' };

    const submit = useSubmit();
    const accept = () => {
        submit(null, { method: "post", action: "delete" })
    };

    const reject = () => {
    };

    const deleteSource = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir supprimer cette source ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept,
            reject
        });
    };

    
    const runBtcDcaReverseOnBitget = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir excecuter le DCA BTC ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => { acceptBtcDca(); },
            reject
        });
    };
    const acceptBtcDca = () => {
        var price = formik.values.sellprice;
        var r = runBtcDcaReverse(source.publicId, price);
        r.then((ret) => {
            if (ret.status === 200) {
                send("success", ret.message)
                notify();
            }

        });
    };

    const runEthDcaReverseOnBitget = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Êtes-vous sûr de vouloir excecuter le DCA ETH ?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => { acceptEthDca(); },
            reject
        });
    };
    const acceptEthDca = () => {
        var price = formik.values.sellEthprice;
        var r = runEthDcaReverse(source.publicId, price);
        r.then((ret) => {
            if (ret.status === 200) {
                send("success", ret.message)
                notify();
            }

        });
    };

    const runDcaBuyOnBitget = (event) => {
        // message different en fonction de la valeur du prix
        var message = 'Êtes-vous sûr de vouloir excecuter le DCA ?';
        if (formik.values.buyprice <=0) {
            message = 'Êtes-vous sûr de vouloir forcer l\'arrêt du robot ?';
            }
        confirmPopup({
            target: event.currentTarget,
            message: message,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => { acceptDcaBuy(); },
            reject
        });
    };
    const acceptDcaBuy = () => {
        var price = formik.values.buyprice;
        var r = runDcaBuy(source.publicId, price);
        r.then((ret) => {
            if (ret.status === 200) {
                send("success", ret.message)
                notify();
            }

        });
    };



    // Formik ---------------------

    const formik = useFormik({
        initialValues: {
            buyprice: 0.0,
            sellprice: 0.0,
            sellEthprice: 0.0,
        },
        validate: (data) => {
            let errors = {};

            if (!data.price) {
                errors.price = 'price is required.';
            }
           

            return errors;
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);


    //----------------------------------------




    return (
        <div>
            <BreadCrumb model={items} home={home} />
        <div id="thumbnail">
            <div>
                <img height="192" key={source.publicId} alt={source.name} src={source.base64Picture || null} />
            </div>

            <div>
                <h1>
                    {source.name ? (
                        <>
                            {source.name}
                        </>
                    ) : (
                        <i>Inconnu</i>
                    )}{" "}
                    <Favorite source={source} />
                </h1>

                {source.link && (
                    <p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={source.link}
                        >
                            {source.link}
                        </a>
                    </p>
                )}


                <div>
                        <Form action="edit">
                            <Button type="submit" size="small">Modifier</Button>
                    </Form>
                    <Form action="assets/create">
                            <Button type="submit" size="small">Créer un actif</Button>
                    </Form>
                        <ConfirmPopup />
                        <Button onClick={deleteSource} icon="pi pi-trash" label="" size="small" className="p-button-danger"></Button>
   

                       
                </div>

            </div>

            </div>


            <div id="mainContent">
                <h2>ETH : Achat DCA</h2>
                <span className="p-float-label">
                    <InputText id="buyprice" name="buyprice" value={formik.values.buyprice}
                        keyfilter="num"
                        onChange={(e) => {
                            formik.setFieldValue('buyprice', e.target.value);
                        }}
                        className={classNames({ 'p-invalid': isFormFieldInvalid('buyprice') })}
                    />


                    <label htmlFor="buyprice">Prix d'achat (ETHUSDC)</label>
                    <Button onClick={runDcaBuyOnBitget} icon="pi pi-play" label="" size="small" ></Button>


                </span>
               
             
                <Chart type="line" data={chartBuyData} options={chartOptions} style={{ position: 'relative', width: '97%' }} />
              
                <h2>BTC : Vente DCA</h2>
                <span className="p-float-label">
                    <InputText id="sellprice" name="sellprice" value={formik.values.sellprice}
                        keyfilter="num"
                        onChange={(e) => {
                            formik.setFieldValue('sellprice', e.target.value);
                        }}
                        className={classNames({ 'p-invalid': isFormFieldInvalid('sellprice') })}
                    />

                    <label htmlFor="sellprice">Prix de vente (BTCUSDC)</label>
                    <Button onClick={runBtcDcaReverseOnBitget} icon="pi pi-play" label="" size="small" ></Button>


                </span>

                

                
                <div>
                    <h3>Dernières ventes :</h3>
                    <ul>
                       
                        {lastSellRun && lastSellRun.map((run) => (
                            <li key={run.id}>
                                Date : {run.createdAt}
                                <br />
                                Prix : {run.price}
                                <br />
                                Allocation : {run.allocation}
                                <br />
                                Status : {run.dcaRun.status}

                            </li>

                        ))}
                        </ul>
                    </div>
                <Chart type="line" data={chartSellData} options={chartOptions} style={{ position: 'relative', width: '97%' }} />



                <h2>ETH : Vente DCA</h2>
                <span className="p-float-label">
                    <InputText id="sellEthprice" name="sellEthprice" value={formik.values.sellEthprice}
                        keyfilter="num"
                        onChange={(e) => {
                            formik.setFieldValue('sellEthprice', e.target.value);
                        }}
                        className={classNames({ 'p-invalid': isFormFieldInvalid('sellEthprice') })}
                    />

                    <label htmlFor="sellEthprice">Prix de vente (ETHUSDC)</label>
                    <Button onClick={runEthDcaReverseOnBitget} icon="pi pi-play" label="" size="small" ></Button>


                </span>
            </div>

    </div>
    );
}

function Favorite({ source }) {
    const fetcher = useFetcher();
    // yes, this is a `let` for later
    let favorite = source.isFavorite;
    if (fetcher.formData) {
        favorite = fetcher.formData.get("favorite") === "true";
    }
    return (
        <fetcher.Form method="post">
            <button
                name="favorite"
                value={favorite ? "false" : "true"}
                aria-label={
                    favorite
                        ? "Remove from favorites"
                        : "Add to favorites"
                }
            >
                {favorite ? "★" : "☆"}
            </button>
        </fetcher.Form>
    );
}