import { get, post, put, del } from "../../data/api";
export async function getInvestment(id) {
    return await get('investment/' + id)
}
export async function getLast() {
    return await get('futures/last/10')
}
export async function deleteTransaction(id) {
    return await del('futures/' + id)
}
export async function getOrder(id) {
    return await get('futures/order/' + id)
}
export async function cancelOrder(id, market = false) {
    let data = {
        Market: market
    };
    return await post(data,'futures/order/cancel/' + id)
}
