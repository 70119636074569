import { get, post, put } from "../data/api";

export async function register(params) {
    let reg = {
        Email: params.registerEmail,
        Password: params.registerPwd,
        Firstname: params.registerFname,
        Lastname: params.registerLname

    };
    return await post(reg, 'authenticate/register/MoneyModule')

}

export async function update(params, id) {
    let reg = {
        Email: params.email,
        CurrentPassword: params.currentPwd,
        NewPassword: params.newPwd,
        Firstname: params.fname,
        Lastname: params.lname,
        Addr1: params.addr1,
        Addr2: params.addr2,
        Phone: params.phone,

    };

    return await put(reg, 'account/')
}
export async function getAccount() {
    return await get('account/')
}

export async function testBinancebConnect(key, secret) {
    let b = {
        Key: key,
        Secret: secret
    };

    return await post(b, 'authenticate/test-binance-connect')
}