import { PrimeReactProvider } from "primereact/api";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-tooltip/dist/react-tooltip.css'
import "primereact/resources/themes/mira/theme.css";
import 'primeicons/primeicons.css';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";


import ErrorPage from "./error-page";


// routes MONEY
import MoneyRoot, { loader as moneyRootLoader, action as moneyRootAction } from "./routes/money/moneyroot";
import MoneyIndex from "./routes/money/index";
//Source
import Source, { loader as sourceLoader, action as sourceAction } from "./routes/money/source/index";
import SourceTradingBot, { loader as sourceTradingBotLoader, action as sourceTradingBotAction } from "./routes/money/source/index_tradingbot";
import SourceFuturesTrading, { loader as sourceFuturesTradingLoader, action as sourceFuturesTradingAction } from "./routes/money/source/index_futures";
import SourceForm, { action as sourceFormAction, loader as sourceFormLoader, } from "./routes/money/source/form";
import { action as deleteSourceAction } from "./routes/money/source/delete";
//tradingbot
//Investment
import Investment, { loader as investmentLoader, } from "./routes/money/investment/index";
import AddInvestment, { action as addInvestmentAction, loader as addInvestmentLoader, } from "./routes/money/investment/add";
import EditInvestment, { loader as editinvestmentLoader, action as editinvestmentAction, } from "./routes/money/investment/edit";
import { action as deleteInvestmentAction } from "./routes/money/investment/delete";

//Asset
import CreateAsset, { action as createAssetAction, loader as createAssetLoader, } from "./routes/money/asset/create";


import Login from "./routes/login";
import Subscription from "./routes/subscription";
import { loader as switchAccountLoader } from "./routes/switch";
import Register, { action as registerAction } from "./routes/register";
import { loader as logoutloader } from "./routes/logout";

import Account, { loader as accountLoader, action as accountAction } from "./routes/account";


const router = createBrowserRouter([
    {
        path: "/",
        element: <MoneyRoot />,
        errorElement: <ErrorPage />,
        loader: moneyRootLoader,
        action: moneyRootAction,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true, element: <MoneyIndex />
                    },
                    {
                        path: "sources/:sourceId",
                        element: <Source />,
                        loader: sourceLoader,
                        action: sourceAction,
                        children: [
                            {
                                path: "investments/add",
                                element: <AddInvestment />,
                                action: addInvestmentAction,
                                loader: addInvestmentLoader,
                            },
                         ],
                    },
                    {
                        path: "tradingbot/:sourceId",
                        element: <SourceTradingBot />,
                        loader: sourceTradingBotLoader,
                        action: sourceTradingBotAction,
                    },
                    {
                        path: "futures/:sourceId",
                        element: <SourceFuturesTrading />,
                        loader: sourceFuturesTradingLoader,
                        action: sourceFuturesTradingAction
                    },
                    {
                        path: "sources/:sourceId/investments/:investmentId",
                        element: <Investment />,
                        loader: investmentLoader,
                    },
                    {
                        path: "sources/:sourceId/edit",
                        element: <SourceForm />,
                        loader: sourceFormLoader,
                        action: sourceFormAction,
                    },
                    {
                        path: "sources/:sourceId/investments/:investmentId/edit",
                        element: <EditInvestment />,
                        loader: editinvestmentLoader,
                        action: editinvestmentAction,
                    },
                    {
                        path: "sources/:sourceId/assets/create",
                        element: <CreateAsset />,
                        action: createAssetAction,
                        loader: createAssetLoader,
                    },
                    
                    {
                        path: "sources/add",
                        element: <SourceForm />,
                        action: sourceFormAction,
                        loader: sourceFormLoader,
                    },
                    {
                        path: "sources/:sourceId/delete",
                        action: deleteSourceAction,
                        errorElement: <div>Oops! There was an error.</div>,
                    },
                    {
                        path: "sources/:sourceId/investments/:investmentId/delete",
                        action: deleteInvestmentAction,
                        errorElement: <div>Oops! There was an error.</div>,
                    },
                    {
                        element: <Account />,
                        path: "moncompte",
                        action: accountAction,
                        loader: accountLoader,
                        errorElement: <ErrorPage />,
                    },
                ],
            },
        ],
    },
    {
        element: <Login />,
        path: "/login",
        children: [
            {
                path: "switch/:childId",
                loader: switchAccountLoader,
            },
        ],
    },
    {
        element: <Register />,
        path: "/inscription",
        action: registerAction
    },
    {
        path: "/logout",
        loader: logoutloader,
        errorElement: <div>Oops! There was an error.</div>,
    },
 
]);
ReactDOM.createRoot(document.getElementById("root")).render(
    <PrimeReactProvider>
        <RouterProvider router={router} />
    </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
