import React, { useState } from "react";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import Moment from 'react-moment';
import {
    Form,
    redirect,
    useSubmit,
    useLoaderData,
} from "react-router-dom";
import { useFormik } from 'formik';
import { update, getAccount, testBinancebConnect } from "../data/accounts";
import { getCurrentSubscription } from "../data/auth.service";
import { send, notify } from "../services/NotificationService";
import { IsNullOrEmpty } from "../services/CustomService";

import { Avatar } from 'primereact/avatar';
export async function loader({ params }) {
    const acc = await getAccount();
    const sub = await getCurrentSubscription();

    return { acc, sub };
}
export async function action({ request, params }) {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    await update(data);

    send("success", "Modifications effectuées !")
    return redirect(`/moncompte`);
}

export default function Account() {
    const { acc, sub } = useLoaderData();
    const [value, setValue] = useState('');
    const header = <div className="font-bold mb-3">Choisir un mot de passe</div>;


    const footer = (
        <>
            <Divider />
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>Au moins une minuscule</li>
                <li>Au moins une majuscule</li>
                <li>Au moins un chiffre</li>
                <li>Au moins un caractère non-alphanumérique</li>
                <li>Minimum 6 caractères</li>
            </ul>
        </>
    );

    var email, address, zipcode, city, phone, fname, lname, subscriptionActivate, binanceKey = "";
    if (acc) {
        email = acc.email;
        address = acc.address;
        zipcode = acc.zipcode;
        city = acc.city;
        phone = acc.phone;
        fname = acc.firstname;
        lname = acc.lastname;
        subscriptionActivate = acc.subscription;
        binanceKey = acc.binanceApiKey;

    }

    // Formik ---------------------
    const submit = useSubmit();
    const formik = useFormik({
        initialValues: {
            email: email ?? '',
            currentPwd: '',
            newPwd: '',
            address: address ?? '',
            zipcode: zipcode ?? '',
            city: city ?? '',
            phone: phone ?? '',
            fname: fname ?? '',
            lname: lname ?? '',
            subscriptionActivate: subscriptionActivate === "1" ? true : false,
            binanceKey: binanceKey ?? '',

        },
        validate: (data) => {
            let errors = {};

            if (data.newPwd && data.newPwd !== data.confirmPwd) {
                errors.newPwd = 'Les mots de passe ne correspondent pas';
            }

            return errors;
        },
        onSubmit: async (data) => {
            submit(data, { method: "post" })
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);


    const testBinanceConnectAction = () => {

        // recuperation user et mdp
        var key = formik.values.binanceKey;
        var secret = formik.values.binanceSecret;

        if (IsNullOrEmpty(key) || IsNullOrEmpty(secret)) {
            send("error", "Merci de remplir tous les champs")
            notify();
            return;
        }

        let a = testBinancebConnect(key, secret);
        a.then((a) => {
            if (a === true)
                send("success", "La connexion semble fonctionner ! Les paramètres ont été enregistrés.")
            else
                send("error", "Il semble y avoir un souci. Merci de vérifier vos données")
            notify();
        });

        notify();
    };

    return (
        <div >
            <h1>Mon compte</h1>

            <Form method="post" id="thumbnail-form" onSubmit={formik.handleSubmit}>
                <Panel header="Identit&eacute;">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="fname" name="fname" value={formik.values.fname}
                                onChange={(e) => {
                                    formik.setFieldValue('fname', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('fname') })}
                            />
                            <label htmlFor="fname">Pr&eacute;nom</label>
                        </span>

                        <span className="p-float-label">
                            <InputText id="lname" name="lname" value={formik.values.lname}
                                onChange={(e) => {
                                    formik.setFieldValue('lname', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('lname') })}
                            />
                            <label htmlFor="lname">Nom</label>
                        </span>
                    </div>
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText disabled id="email" name="email" value={formik.values.email}
                                onChange={(e) => {
                                    formik.setFieldValue('email', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('email') })}
                            />
                            <label htmlFor="email">Email</label>
                        </span>
                    </div>
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
                <Panel header="Mot de passe">
                    <div className="contentForm">
                        <span className="p-float-label">

                            <Password id="currentPwd" name="currentPwd" value={formik.values.currentPwd}
                                feedback={false}
                                onChange={(e) => {
                                    formik.setFieldValue('currentPwd', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('currentPwd') })}
                            />
                            <label htmlFor="currentPwd">Mot de passe actuel</label>
                        </span>
                        <div className="card flex justify-content-center">
                            <span className="p-float-label">

                                <Password id="newPwd" name="newPwd" value={formik.values.newPwd} header={header} footer={footer}
                                    promptLabel=" " weakLabel="Trop simple" mediumLabel="Moyennement complexe" strongLabel="Très complexe !"

                                    onChange={(e) => {
                                        formik.setFieldValue('newPwd', e.target.value);
                                        setValue(e.target.value)
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('newPwd') })}
                                />
                                <label htmlFor="newPwd">Nouveau</label>
                            </span>
                        </div>
                        <span className="p-float-label">

                            <Password id="confirmPwd" name="confirmPwd" value={formik.values.confirmPwd}
                                feedback={false}
                                onChange={(e) => {
                                    formik.setFieldValue('confirmPwd', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('confirmPwd') })}
                            />
                            <label htmlFor="confirmPwd">Confirmation</label>
                        </span>
                    </div>
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
                <Panel header="Adresse et contact">
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="address" name="address" value={formik.values.address}
                                onChange={(e) => {
                                    formik.setFieldValue('address', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('adaddressdr1') })}
                            />
                            <label htmlFor="address">Num&eacute;ro et rue</label>
                        </span>
                        <span className="p-float-label">
                            <InputText id="zipcode" name="zipcode" value={formik.values.zipcode}
                                onChange={(e) => {
                                    formik.setFieldValue('zipcode', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('zipcode') })}
                            />
                            <label htmlFor="zipcode">Code Postal</label>
                        </span>
                        <span className="p-float-label">
                            <InputText id="city" name="city" value={formik.values.city}
                                onChange={(e) => {
                                    formik.setFieldValue('city', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('city') })}
                            />
                            <label htmlFor="city">Ville</label>
                        </span>
                    </div>
                    <div className="contentForm">
                        <span className="p-float-label">
                            <InputText id="phone" name="phone" value={formik.values.phone}
                                onChange={(e) => {
                                    formik.setFieldValue('phone', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('phone') })}
                            />
                            <label htmlFor="phone">T&eacute;l&eacute;phone</label>
                        </span>
                    </div>
                    <div className="contentForm"></div >
                </Panel>
                <div className="contentForm"></div >
                <Panel className="module" header="Clés API" >


                    <div className="contentForm">
                        <div className="serviceTitle">

                            <Avatar image="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAQNSURBVFhH7ZhLSFVBHMbnqmlEKr20h1hhZRG96B3YxrKiImgRiWarWrSMXlaEBhVF0aqIslWk0U5QgrbZS91YRFZKIlFpooSIJnJP3zfnzHXu9Zx7Hl5d+cHPe2bOnDmf8/ifmRFTGqdC1m8Q5YF9YAdYDXJBOqD6QSf4BN6AF6AdTLhSQAl4C8LA8AjL8hk+yzomRHvBZ2BnwA+sg3UlTDPAI+CnxdxgXVWAdY9L2aAJ2L0kEbDu+SCQaC4RXepGK/Btkk3vu+XOl2Qb5aXZtvdc4Lt8dTfHnF1FjtBc/8t1koAmOSbHKNn61cUZdgd4jpEwJy4fH+2lgnUz5SsbPgxYOZ60ATSCNpmyFGuQMaoWzJMpD4o1pxTAJBtkE3gAOMulYg0Wg5PmpbuczCkFMDkXfAMfZAqKNfgQ5JiX8WVn7kvnkOj5OyLmZo5+LAKYXAQi41E3yG/rTeA69pzM7T/XLqpf9omirRnjMUmDT0EvE0n8Y4kffldzSxekijPFWVbKlDLX1TsiuvpGxIGz7aIVebpOH80SeQvTrFRc0QO9SOkGuSpx1fdfw6K4okMMDZvjWDenJE0ij/coli2p7BDtP//JtAdtt36j1ALQEfacODjHyJ6dEkkXbkw3Gu6viMrbtSnd2L05PZLmPZYp0vNmpci6VNoBehmjPmBX2LiAwMsA3FyVH2UoKWm0TCHM/albI9EN6WX4bBPq8BDM6UVKnyTXgN7lUqhIXCozJwQH/p4tGaK24a8YGAwLg1VBaE3xrHKJmJ6aJFKSQ+JQQaZo+TaILh2OlIE5UX8rT6zMnS7TLhMnFVzlxRhDsQpbL1AKhUIYxdFzCa0UJVkG6KJRZVYp5Pr2aDl2McKK2cWP8435s6dF8vXuY7eye3vq1xpoZdsyWRh/TY/MLr54zFsX6/I3STDmYicJTermaOjVveVG0ZbRMcm8oJOkGtgVHgNnK1vLbuIoaKTRaq0elNWNe4BepPRRwN2XqxioayrMCUHlY9Bz8HMSKMGcqEPeqsXmhEhD2adXFotlOZ4CNcUNlpRukFtDuo8rBurbNd1WypRukuZ4rcwp3X3eLdp+eArU9EAvUrGfNjrfZl7Gl9P3mLWrUKJ048lvcf1Jl5Vy1XsQ8RC7muG36bB5GV+vPw6IML52O9cjnllinNQXCZRPc1Q5cFxucZN0BHBd5io7k7oCmPsCTgHHBStv8IiCJwCuKxvKyWQAcxwdZeCrTLmIC0Y+4BkVzEl5/CDshO2myUmTve1sBr5PGThFuam2qzCRBNq4K/HBiTz6YMsFNqfEpuf44ASye0kQWBfr9N2t8ZSo4zd2aWTPkWgxCgc9wHwHSkF0JHeRp1jnIK9HwPxn+G2NOtKY0uRIiP9UVRMaEqLgYwAAAABJRU5ErkJggg==" size="large" shape="circle" />
                            <span className="font-bold"></span>
                        </div>

                        <span className="p-float-label">
                            <InputText id="binanceKey" name="binanceKey" value={formik.values.binanceKey}
                                onChange={(e) => {
                                    formik.setFieldValue('binanceKey', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('binanceKey') })}
                            />
                            <label htmlFor="binanceKey">Key</label>
                        </span>

                        <span className="p-float-label">
                            <Password id="binanceSecret" name="binanceSecret" value={formik.values.binanceSecret}
                                feedback={false}
                                onChange={(e) => {
                                    formik.setFieldValue('binanceSecret', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('binanceSecret') })}
                            />
                            <label htmlFor="binanceSecret">Secret</label>
                        </span>
                        <span className="p-float-label">
                            <Button size="small" type="button" onClick={() => testBinanceConnectAction()} severity="secondary">Test</Button>
                        </span>
                    </div>
                    
                    <div className="contentForm"></div>
                </Panel>

               
                <p> <Button type="submit" size="small">Enregistrer</Button></p>
                <div className="contentForm"></div >
            </Form>
        </div>
    );
}