//https://reactrouter.com/en/main/start/tutorial
//https://primereact.org/button/
import { Menu } from 'primereact/menu';
import { ToastContainer } from 'react-toastify';
import { Button } from 'primereact/button';
import { useRef } from "react";
import { MenuBar } from './menubar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { getCurrentUser } from "../../data/auth.service";
import { getMapping } from "../../routes/ModulesMapping";
import { notify, send } from "../../services/NotificationService";
import {
    Outlet,
    NavLink,
    useLoaderData,
    redirect,
    useNavigation,
} from "react-router-dom";
import { getSources } from "../../data/money/sources";
export async function action() {
    return redirect(`/sources/add`);
}
export async function loader({ request }) {
    const sources = await getSources();
    return {  sources };
}
export default function MoneyRoot() {
    const { sources } = useLoaderData();
    const navigation = useNavigation();
    const menuLeft = useRef(null);
    const items = [
        {
            label: 'Services',
            items: [
                
                {
                    label: 'Investisements',
                    icon: 'pi pi-euro',
                    className: 'menu-active'
                }
            ]
        }
    ];

    notify();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />

            <div className="card scrollpanel-demo">
                <div className="flex flex-column md:flex-row gap-5">

                <div id="sidebar">
                

                        <div id="headerModule" className="card flex justify-content-center">
                    <div className="card flex justify-content-center">
                  
                        <Menu   model={items} popup ref={menuLeft} id="popup_menu_left" />
                                <Button data-cy="hamburgerBtn"  size="small" label="" icon="pi pi-align-left" className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
                            </div>

                            <h2>Investissements</h2>
                        </div>
                   

               
                <ScrollPanel style={{ width: '100%' }} className="custombar1">
                <nav>
                    {sources.length ? (
                        <div>
                        
                        
                        <ul>
                            {sources.map((source) => (
                                <li key={source.publicId }>
                                    <NavLink
                                        to={`sources/${source.publicId}`}
                                        className={({ isActive, isPending }) =>
                                            isActive
                                                ? "active"
                                                : isPending
                                                    ? "pending"
                                                    : ""
                                        }
                                    >
                                        {source.name ? (
                                            <>
                                                {source.name}
                                            </>
                                        ) : (
                                            <i>Inconnu</i>
                                        )}{" "}
                                        {source.isFavorite && <span>★</span>}
                                    </NavLink>
                                </li>
                            ))}
                            </ul></div>
                    ) : (
                        <p>
                            <i>Aucune source</i>
                        </p>
                    )}
                </nav>
                </ScrollPanel>
            </div>
            <div id="detail" className={navigation.state === "loading" ? "loading" : ""}>
                <ScrollPanel style={{ width: '100%' }} className="custombar2">
                    <MenuBar />
                    <Outlet />
                </ScrollPanel>
            </div>
                </div>
            </div>
        </>
    );
}