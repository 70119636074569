import { get, post, put, del } from "../../data/api";

export async function getSources() {
    return await get('source')
}
export async function getSource(id) {
    return await get('source/' + id)
}
export async function getSourceTypes() {
    return await get('source/types')
}
export async function updateFavorite(id, updates) {
    let fav = {
        IsFavorite: updates.favorite
    };

    return await put(fav, 'source/' + id)
}
export async function addSource(params) {
    let source = {
        Name: params.name,
        Link: params.link,
        Base64Picture: params.picture,
        CustomUrl: params.customurl,
        AssetType: params.type
    };
    return await post(source, 'source')

}
export async function updateSource(params, id) {
    let source = {
        Name: params.name,
        Link: params.link,
        Base64Picture: params.picture,
        CustomUrl: params.customurl,
        AssetType: params.type
    };

    return await put(source, 'source/' + id)
}
export async function deleteSource(id) {
    return await del('source/' + id)
}
export async function sellEverything(sourceId) {
    let source = {
        SourceId: sourceId,

    };
    return await post(source, 'external/binance-sell-everything')

}

export async function runBtcDcaReverse(sourceId, price) {
    let source = {
        SourceId: sourceId,
        Price: price
    };
    return await post(source, 'investment/run-btc-dca-reverse')

}
export async function runEthDcaReverse(sourceId, price) {
    let source = {
        SourceId: sourceId,
        Price: price
    };
    return await post(source, 'investment/run-eth-dca-reverse')

}
export async function sendOrder(pe, sl,tp, size, pair) {
    let source = {
        EntryPoint: pe,
        StopLoss: sl,
        Size: size,
        Pair: pair,
        TakeProfit: tp
    };
    return await post(source, 'investment/send-order')

}


export async function runDcaBuy(sourceId, price) {
    let source = {
        SourceId: sourceId,
        Price: price
    };
    return await post(source, 'investment/run-dca-buy')

}
export async function getStats(strat) {
    return await get('source/stats/' + strat)
}

export async function getLastRun(strat) {
    return await get('source/stats/current/' + strat)
}

export async function getBalance(sourceId) {
    return await get('source/ ' + sourceId + '/balance')
}